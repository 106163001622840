
$(function(){
    $('#logo_carousel').slick({
        slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3850,
		dots: false,
		arrows: false,
		infinite: true,
		draggable: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		swipeToSlide: false,
		touchMove: false,
		responsive: [{
				breakpoint: 1570,
				settings: {
					slidesToShow: 1,
                    slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
    });
  });


$(function(){
    $('#logo_carousel2').slick({
        slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3850,
		dots: false,
		arrows: false,
		infinite: true,
		draggable: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		swipeToSlide: false,
		touchMove: false,
		responsive: [{
				breakpoint: 1570,
				settings: {
					slidesToShow: 1,
                    slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
    });
  });