jQuery(document).ready(function ($) {
    // $('#header_nav .menu-item').mouseenter(
    //     function () {
    //         //check to see if menu-item is part of a submenu
    //         if (!$(this).parent().hasClass('sub-menu')) {
    //             $('.sub-menu').removeClass('open');
    //             $('#header').removeClass('open');

    //             $(this).find('.sub-menu').addClass('open');

    //             $(this).addClass('open');
    //             if ($('.sub-menu').hasClass('open')) {
    //                 $('#header').addClass('open');
    //             }

    //         }
    //     }
    // );
    // $('#header_nav .menu-item').mouseleave(
    //     function () {
    //         //check to see if menu-item is part of a submenu
    //         if (!$(this).parent().hasClass('sub-menu')) {
    //             $('.sub-menu').removeClass('open');
    //             $(this).removeClass('open');
    //             $('#header').removeClass('open');

    //         }
    //     }
    // );
    $('body').click(function (e) {

        var target = $(e.target),
            article;

        if (target.is('.mobile-nav-button') || target.is('.bar1') || target.is('.bar2') || target.is('.bar3')) {
            console.log('button')
        } else {
            if ($(".nav-closed").hasClass("open")) {
                $(".nav-closed").removeClass("open");
                $(".bar1").removeClass("change");
                $(".bar2").removeClass("change");
                $(".bar3").removeClass("change");
            }
        }

    });​
    $(".mobile-nav-button").click(function () {
        $(".nav-closed").toggleClass("open");
        $(".bar1").toggleClass("change");
        $(".bar2").toggleClass("change");
        $(".bar3").toggleClass("change");
    })
})